/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { isPresent } from '@ember/utils';
export interface HarborFormTagsSignature {
  Element: HTMLInputElement;
  Args: {
    name?: string;
    placeholder?: string;
    hasErrors?: boolean;
    selectedTags: unknown[];
    options?: unknown[];
    noMatchesMessage?: string;
    renderInPlace?: boolean;
    onChange?: (tags: unknown[]) => void;
  };
}

export default class HarborFormTags extends Component<HarborFormTagsSignature> {
  get availableTags() {
    const { selectedTags, options } = this.args;
    const selected = selectedTags ?? [];
    return (options ?? []).filter((x) => {
      return selected.indexOf(x) < 0;
    });
  }

  get renderInPlace() {
    return this.args.renderInPlace ?? true;
  }

  @action
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  addTag(select: any, e: KeyboardEvent) {
    if (e.keyCode === 13 && select.isOpen && !select.highlighted && isPresent(select.searchText)) {
      if (!select.selected?.includes(select.searchText)) {
        select.options.pushObject(select.searchText);
        select.actions.choose(select.searchText);
      }
    }
  }

  @action
  onChangeCallback() {
    if (this.args.onChange) {
      this.args.onChange(this.args.selectedTags);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Harbor::Form::Tags': typeof HarborFormTags;
  }
}
