/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

import type HarborFormInput from './input';
import type HarborFormTags from './tags';
import type PowerSelect from 'ember-power-select/components/power-select';
import type EmberFlatpickr from 'ember-flatpickr/components/ember-flatpickr';

export interface HarborFormFieldSignature {
  Element: HTMLDivElement;
  Args: {
    /**
     * A label should be provided, even if won't be displayed.
     * This is because the label is used to generate the `for` attribute of the input.
     */
    labelText?: string;
    hideLabel?: boolean;
    labelClass?: string;
    inputWrapperClass?: string;
    optional?: boolean;
    errors?: string[];
  };
  Blocks: {
    default: [
      {
        Input: typeof HarborFormInput;
        Tags: typeof HarborFormTags;
        PowerSelect: typeof PowerSelect;
        Flatpickr: typeof EmberFlatpickr;
      },
    ];
    /**
     * The `label` block is optional, but it lets you customize (use HTML) the label for the input.
     * In case you want to use the default label, you can use the `labelText` argument.
     */
    label: [];
  };
}
/**
 * Utility wrappers for input components, providing an easy and consistent way of displaying labels,
 * optional text, and error messages.
 *
 * Yields
 * * `Input` block (HarborInputComponent)
 * * `label` name-block.
 */
export default class HarborFormField extends Component<HarborFormFieldSignature> {
  get forName(): string {
    return this.args.labelText?.toLowerCase().replace(' ', '-') || guidFor(this);
  }

  get hasErrors(): boolean {
    return Boolean(this.args.errors && this.args.errors.length > 0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Harbor::Form::Field': typeof HarborFormField;
  }
}
