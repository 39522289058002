import type templateOnlyComponent from '@ember/component/template-only';
import EmberFlatpickr from 'ember-flatpickr/components/ember-flatpickr';
import { guidFor } from '@ember/object/internals';

import type flatpickr from 'flatpickr';

type FlatpickrOptions = flatpickr.Options.Options;

interface HarborFormDatePickerSignature {
  Element: HTMLInputElement;
  Args: FlatpickrOptions & {
    name?: string;
    hasErrors?: boolean;
    clearDate?: boolean;
    date: FlatpickrOptions['defaultDate'];
    disabled: boolean;
  };
}

export type HarborFormDatePickerType = ReturnType<
  typeof templateOnlyComponent<HarborFormDatePickerSignature>
>;

export default class extends EmberFlatpickr {
  get name(): string {
    // We can use type param with EmberFlatpickr
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    return this.args.name || guidFor(this);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Harbor::Form::DatePicker': HarborFormDatePickerType;
  }
}
