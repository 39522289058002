/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';

export interface HarborFormTextareaSignature {
  Element: HTMLTextAreaElement;
  Args: {
    name?: string;
    hasErrors?: boolean;
    value?: string;
  };
}

/**
 * Nothing is strictly required to instantiate a `HarborFormTextarea`, but it is recommended
 * that you use the `HarborFormField` component instead.
 *
 * This components uses `...attributes` so that you can pass in any valid input attributes
 */
export default class HarborFormTextarea extends Component<HarborFormTextareaSignature> {
  get name(): string {
    return this.args.name || guidFor(this);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Harbor::Form::Textarea': typeof HarborFormTextarea;
  }
}
